import React, { Component } from "react";
import { Link } from "gatsby";
import { Drawer } from "antd";
import Logo from "../../images/curbside-logo.png";
// import leedIcon from "../../images/leed-icon.svg";
import leedDarkIcon from "../../images/leeddark-icon.svg";
import LeedIcon from "../../images/leed-icon.svg";
import MenuIcon from "../../images/menuicon.svg";
const LOGIN_URL = process.env.LOGIN_URL

class Headerpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isTop: true,
      pathname: ""
    }
  }
  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window && window.scrollY < 200;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
      }
    });
    if(window && window.location.pathname) {
      this.setState({ pathname: window.location.pathname})
    }
  }
  // Title Update
  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onCloseDrawer = () => {
    this.setState({
      visible: false
    });
  };
  render() {

    const pathname = this.state.pathname
    return (
      <div>
        <nav className={this.state.isTop ? 'navbar navbar-expand-lg navbar-light top-navbar' : 'navbar navbar-expand-lg navbar-light top-navbar fixed-top hasScroll'}>
          <div className="container-fluid">
            <div className="main__header d-flex align-items-center">
              <div className="main__header--logo">
                <Link to={`/`}>
                  <img src={Logo} alt="" />
                </Link>
              </div>
              <div className="main__header--left ml-auto">
                <ul className="main__header--links">
                       {/* <li>
                    <Link className={pathname === "/business" ? "active" : ""} to={`/curbside-now`}>
                      Curbside Now
                    </Link>
                  </li>
                  <li>
                    <Link className={pathname === "/business" ? "active" : ""} to={`/business`}>
                      For Businesses
                    </Link>
                  </li>
                  <li>
                    <a className={pathname === "/" ? "active" : ""} href={LOGIN_URL} target="_blank" rel="noopener noreferrer">
                      Login
                    </a>
                  </li> */}
                  <li>
                    {/* <Link className={pathname === "/" ? "active" : ""} to={`/manifest-report`}> */}
                      <img src={this.state.isTop && pathname === "/" ? LeedIcon : leedDarkIcon} alt="" />
                    {/* </Link> */}
                  </li>
                  <li>
                    <Link
                      className="btn btn-order yellowbtn"
                      to={`/curbside-now`}
                    >
                      Order Now
                  </Link>
                  </li>
                  {/* <li>
                    <a href="javascript:void(0)" className={pathname === "/about" || pathname === "/technology" || pathname === "/faq" ? "active" : ""}>About ▼</a>
                    <ul className="navbar-submenu">
                      <li>
                        <Link to={`/about`}>
                          Company
                        </Link>
                      </li>
                      <li>
                        <Link to={`/technology`}>
                          Technology
                        </Link>
                      </li>
                      <li>
                        <Link to={`/faq`}>
                         FAQ
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                </ul>

                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.showDrawer}
                >
                  {/* <span className="navbar-toggler-line"></span> */}
                  <img src={MenuIcon} alt="" />
                </button>

              </div>
            </div>
          </div>
        </nav>

        <Drawer
          className="mobile-drawer"
          placement="left"
          closable={true}
          width="320"
          onClose={this.onCloseDrawer}
          visible={this.state.visible}
        >
          <div>
            <ul className="mobile-custom-links">
              <li>
                <Link className="active" to={`/`}>
                  Home
                </Link>
              </li>
              <li>
                <Link className={pathname === "/business" ? "active" : ""} to={`/curbside-now`}>
                  Curbside Now
                    </Link>
              </li>
              <li>
                <Link className={pathname === "/business" ? "active" : ""} to={`/business`}>
                  For Business
                </Link>
              </li>
              <li>
                <Link to={`/about`}>About</Link>
              </li>
              <li>
                <Link to={`/curbside-now/faq/`}>FAQ</Link>
              </li>
              <li>
                <a className={pathname === "/" ? "active" : ""} href={LOGIN_URL} target="_blank" rel="noopener noreferrer">
                  Login
                </a>
              </li>
              <li>
                <Link
                  className="btn btn-order-mob"
                  to={`/curbside-now`}
                >
                  Order Now
                  </Link>
              </li>
            </ul>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default Headerpage;
